import axios from 'axios';
import Cookies from 'universal-cookie';
const cookies = new Cookies();

const domainurl = 'taihapo.com.cn';//'tyrehubtech.com';//'taihapo.com.cn';

//before build, set the baseURL for the target server, or http://everet.eae-ae.com cannot access
// axios.defaults.baseURL = 'https://dashboard.test.taihapo.com.cn/';
// axios.defaults.baseURL = 'https://dashboard.taihapo.com.cn/';
// axios.defaults.baseURL = 'https://dashboard.tyrehubtech.com/';
// axios.defaults.baseURL = 'http://dashboard.iptirescanner.com/';
if (domainurl === 'iptirescanner.com'){
  axios.defaults.baseURL = 'http://dashboard.'+domainurl+'/';
}
else {
  axios.defaults.baseURL = 'https://dashboard.'+domainurl+'/';
}
// request 拦截器
axios.interceptors.request.use(
  config => {
    if (cookies.get('tyrehub-pc')) { // 判断是否存在token，如果存在的话，则每个http header都加上token
      config.headers.Authorization = cookies.get('tyrehub-pc')
    }
    return config
  },
  err => {
    return Promise.reject(err)
  }
);

// response 拦截器
axios.interceptors.response.use(
  response => {
    // token过期
    if (response.data.code === 1101) {
      cookies.remove('tyrehub-pc');
      window.location.reload()
    }
    return response
  },
  error => {
    return Promise.reject(error) // 返回接口返回的错误信息
  }
);

const Api = {
  
  domain_url(){
    return domainurl
  } ,

  /**
   * 登录
   * @param {*} params
   */
  login(params = {}) {
    return axios.put('/api/user/login/', params)
  },

  /**
   * 获取当前用户详情
   * @param {*} params
   */
  getUser(params) {
    return axios.get('/api/current_user/', { params: params })
  },
  
  /**
   * 更新当前用户登录密码
   * @param {*} params
   */
  updatePassword(params = {}) {
    return axios.post('/api/user/updatepassword/', params)
  },

  /**
   * 新增门店
   * @param {*} params
   */
  addStore(params = {}) {
    return axios.post('/api/store/create/', params)
  },

  /**
   * 获取当前用户所属门店信息
   * @param {*} params
   */
  getStore(params) {
    return axios.get('/api/store/', { params: params })
  },

  /**
   * 更新当前用户所属门店信息
   * @param {*} params
   */
  updateStore(params = {}) {
    return axios.put('/api/store/update/', params)
  },

  /**
   * 更新当前用户所属门店Logo
   * @param {*} params
   */
  updateStoreLogo(params = {}) {
    return axios.post('/api/store/logo/', params)
  },


  /**
   * 列出检测报告
   * @param {*} params
   */
  getReports(params) {
    return axios.get('/api/reports/', { params: params })
  },

  /**
   * 获取指定时间段检测报告统计数据
   * @param {*} params
   */
  getStatisticsData(params) {
    return axios.get('/api/reports/statistics/', { params: params })
  },

  
  /**
   * 列出所有门店
   * @param {*} params
   */
  getStores(params) {
    return axios.get('/api/stores/', { params: params })
  },

  /**
   * 列出绑定设备
   * @param {*} params
   */
  getDevices(params) {
    return axios.get('/api/devices/', { params: params })
  },

  /**
   * 绑定设备
   * @param {*} params
   */
  bindDevice(params = {}) {
    return axios.put('/api/devices/binding/', params)
  },

  /**
   * 解绑设备
   * @param {*} params
   */
  unbindDevice(device_id,params) {
    return axios.put(`/api/devices/${device_id}/unbinding/`,params)
  },

  /**
  * 下载检测检测报告
  * @param {*} params
  */
  downloadReports(params) {
    return axios.get('/api/reports/download/', { params: params })
  },
  /**
   * 下载商用车检测检测报告
   * @param {*} params
   */
  downloadLorryReports(params) {
    return axios.get('/api/lorry_reports/download/', { params: params })
  },

  /**
   * 获取检测报告详情
   * @param {*} reportId
   * @param {*} params
   */
  getReportsDetail(reportId, params) {
    return axios.get(`/api/reports/${reportId}/`, { params: params })
  },

  /**
* 获取检测报告详情
* @param {*} reportUuid
* @param {*} params
*/
  getReportsEmail(reportUuid, params) {
    return axios.get(`/api/reports/email/${reportUuid}/`, { params: params })
  },
  /**
 * 获取检测报告详情
 * @param {*} reportUuid
 * @param {*} params
 */
  getReportDetail(reportUuid, params) {
    return axios.get(`/api/lorry_reports/${reportUuid}/`, { params: params })
  },


  /**
   * 下载APP
   * @param {*} params
   */
  getApp(params) {
    return axios.get('/api/app/', { params: params })
  },

  /**
* 获取大车报告列表
* @param {*} params
*/
  getLorList(params) {
    return axios.get('/api/lorry_reports/list/', { params: params })
  },

  /**
* 获取大车报告列表
* @param {*} params
*/
  getLorDetail(Uuid, params) {
    return axios.get(`/api/lorry_reports/${Uuid}/`, { params: params })
  }
};

Object.assign(global, { Api });
